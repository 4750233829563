import { __wrappedFetch } from "./util"

interface ErrorMessageApiResponse {
  status: string,
  remarks: string,
  data: any,
}

const ContentAPI = {
  API_URL: process.env.REACT_APP_API_URL + '/api',
  ENDPOINTS: {
    ip_address: '/server/ip_address.php',
    all: '/server/content.php',
    // all: '/api_content',
    redirect: '/server/redirect.php',
    // redirect: '/frontend-redirect',
    error_messages: '/server/error_messages.php',
    // error_messages: '/error_messages',
    // maintenance: '/frontend-redirect',
    maintenance: '/server/maintenance.php',
  },

  /**
   * Get a dictionary of page contents
   *
   * Note: call this once to reduce server roundtrips
   *
   * @returns Record<K, V> where K = content identifier, V = actual html content
   */
  all(): Promise<any > {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.all}`)
      const response = await __wrappedFetch(`${this.ENDPOINTS.all}`)
        .then(response => response.json())
        .then(response => (response as Array<any>));

      resolve(response.reduce((acc, val) => {
        // convert to K,V record
        acc[val.header_1] = {
          content: val.content_1,
          header: val.page_name,
        };
        return acc;
      }, {}))

      // Slower:
      // resolve(Object.fromEntries(response.map(value => [ value.page_code, value.content_1 ])))
    });
  },
   error_messages() : Promise<ErrorMessageApiResponse> {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.error_messages}`, {
      const response = await __wrappedFetch(`${this.ENDPOINTS.error_messages}`, {
        method: 'POST',
        // headers: { 'X-API-KEY': '0p;/)P:?9ol.(OL>' },
      })
        .then(response => response.json())

      // normalize
      resolve(response)
    })
  },
  redirects(): Promise<Array<any> > {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.redirect}`)
      const response = await __wrappedFetch(`${this.ENDPOINTS.redirect}`)
        .then(response => response.json())
        .then(response => response.settings)
        .then(response => (response as Array<any>));

      resolve(response)
    });
  },
  maintenance(): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.maintenance}`)
      const response = await __wrappedFetch(`${this.ENDPOINTS.maintenance}`)
        .then(response => response.json())
        .then(response => response.down_settings)
        .then(response => (response as Array<any>));
      resolve(response)
    });
  },
  ip_address(): Promise<any > {
    return new Promise(async (resolve, reject) => {
      const response = await __wrappedFetch(`${this.ENDPOINTS.ip_address}`)
        .then(response => response.json());
        resolve(response);
    });
  },
} as const

export default ContentAPI;
