import { useEffect, useState } from "react";

interface TimerProps {
  startAt: number,
  onFinish: VoidFunction
}

export function formatTimer(time: number) {
  // mm:ss
  const m = Math.trunc(time / 60), s = Math.trunc(time % 60);
  return m.toString().padStart(2, '0') + ':' + s.toString().padStart(2, '0');
}

export default function Timer(props: TimerProps) {
  const [time, setTime] = useState(props.startAt); // Countdown timer
  let id: number = undefined!;

  // User has switched back to the tab
  const onFocus = () => {
      console.log("Tab is in focus");
      if(document.hasFocus())
      {
        console.log("BDO site is in focus");

        let stc:any = 0;
        if(sessionStorage.getItem('bdoca_stc'))
        {
          stc = sessionStorage.getItem('bdoca_stc');
          stc = parseInt(stc);
        }

        let ctc = new Date().getTime();
        sessionStorage.setItem('bdoca_ctc', (ctc).toString());

        console.log('Start Time Checkpoint: ' + stc);
        console.log('Current Time Checkpoint: ' + ctc);

        let computed_time = Math.ceil( (props.startAt) - ( ( ctc- stc) /1000 ) );
        console.log('Computed Time: ' + computed_time);
        
        if(computed_time < 0)
        {
          computed_time = 0;
        }

        setTime(computed_time);
      }
      else
      {

        console.log("BDO site is NOT in focus");
      }
  };

  useEffect(() => {

    window.addEventListener("focus", onFocus);

    let ctc = new Date().getTime();
    sessionStorage.setItem('bdoca_stc', (ctc).toString());

    id = window.setInterval(() => {
      setTime((prevState) => prevState - 1)
    }, 1000)

    return () => {
      clearInterval(id);
      window.removeEventListener("focus", onFocus);
    }
  }, []) // Dont pass countdown here

  useEffect(() => {
    if(time <= 0) {
      clearInterval(id)
      props.onFinish();
    }
  }, [ time ])

  return (
    <>
      {formatTimer(time)}
    </>
  );
}
