import LoanAPI from "api/loan"
import { useEffect, useState } from "react"
import { Navigate, useSearchParams } from "react-router-dom"

const Q_URLID = 'urlid'

/**
 * Resolves the reference number linked to urlid.
 * Redirects to /start if urlid is not valid
 */
export default function ResolveRefNo(props: {
  onComplete: (refno: string) => void
}) {
  const [searchParams] = useSearchParams()
  const [loading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(undefined!)

  const validate = async () => {
    const result = await LoanAPI.mapUrlId(searchParams.get(Q_URLID)!);

    if(result.status) {
      props.onComplete(result.refno || '')
    }

    setIsSuccess(result.status === 1 ? true : false)
    setIsLoading(false)
  }

  useEffect(() => {
    validate()
  }, [])

  return (
    <div className="text-center p-3">
      {loading
        ? <p className="fs-5 ">Validating link, please wait...</p>
        : <Navigate to={isSuccess ? "/otp" : "/start"} />
      }
    </div>
  )
}
