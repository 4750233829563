import { useState } from "react"
import { Toast, ToastProps } from "react-bootstrap"

export function InlineSpinner() {
  return (
    <>
      <span className="mx-2"></span>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </>
  )
}

export function DefaultToast(props: {
  bg: string,
  children: React.ReactNode
  visibleState: [boolean, (v: boolean) => void],
}) {
  return (
    <Toast
      onClose={() => props.visibleState[1](false)}
      show={props.visibleState[0]}
      bg={props.bg}

      delay={3500}
      className="shadow-sm"
      autohide
    >
      <Toast.Body className="text-white fw-bold">{props.children}</Toast.Body>
    </Toast>
  )
}

export function Placeholder() {
  return (
    <div className="container px-lg-5 py-5">
      <p className="placeholder-glow">
        <span className="placeholder col-8"></span>
        <span className="placeholder col-10"></span>
        <span className="placeholder col-12"></span>
        <span className="placeholder col-12"></span>
        <span className="placeholder col-11"></span>
        <br />
        <br />


        <span className="placeholder col-8"></span>
        <span className="placeholder col-10"></span>
        <span className="placeholder col-12"></span>
        <span className="placeholder col-11"></span>
        <span className="placeholder col-11"></span>
      </p>
    </div>
  )
}
