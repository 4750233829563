import { useState, useEffect } from "react";
import ContentAPI from "api/content";
import { Modal, ModalProps } from "react-bootstrap";
import Undertaking, { UndertakingProps } from "./Undertaking";
import closeIcon from 'assets/images/x-lg.svg';

interface UndertakingModalProps extends ModalProps {
  // Note: Checking the undertaking checkbox and clicking "I agree" are distinct events
  onAgreeClick: VoidFunction
  onBackClick: VoidFunction
};

const KEY_MECHANICS = 'promo_mechanics_1';

export default function UndertakingModal(props: UndertakingModalProps) {
  const [ checked, setChecked ] = useState(props.defaultChecked);
  const { onBackClick, onAgreeClick, ...other } = props;
  const [content, setContent] = useState({
    content: '',
    header: ''
  });

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    const response = await ContentAPI.all()
    console.log(response);
    setContent(response[KEY_MECHANICS])

  }


  return (
    <Modal {...other} className="undertaking-modal">
      <Modal.Header>
        <div className="header-content">
        { content.header }
        </div>
        <div className="header-control">
          <span className="modal-close" onClick={() => {
            setChecked(false);
            props.onBackClick();
          }}> <img src={closeIcon} className="img-fluid rounded-start" alt="Close Icon" /></span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Undertaking onCheckChange={setChecked} content={content.content} promoMechanicsLink={props.promoMechanicsLink} defaultChecked={false}/>
      </Modal.Body>
      <Modal.Footer>
      {
        // <button
        //   type="button"
        //   className="btn btn-outline-primary"
        //   onClick={() => {
        //     setChecked(false);
        //     props.onBackClick();
        //   }}
        // >
        //   Back
        // </button>
      }
        <button onClick={() => props.onAgreeClick()}
          className={`btn btn-primary ${checked ? '' : 'disabled'}`}
        >
          I Agree
        </button>
      </Modal.Footer>
    </Modal>
  );
}
