import { useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import closeIcon from 'assets/images/x-lg.svg';
import errorIcon from 'assets/images/error-icon.png';
import maintenanceIcon from 'assets/images/site_maintenance-desktop.svg';
// import Undertaking, { UndertakingProps } from "./CustomModalContent";

interface AlertModalProps extends ModalProps {
  onClose: VoidFunction
  content: any
};

export default function UndertakingModal(props: AlertModalProps) {
  const { onClose, content,...other } = props;
  const addDefaultSrc = (event:any) => {
      event.target.src = maintenanceIcon
  };
  return (
    <Modal {...other}>
      <Modal.Header closeButton={false}></Modal.Header>
      <Modal.Body>
        { content.show_icon && <img src={content.image} onError={addDefaultSrc} style={{width: "250px"}} className="error-icon" />}
        { content.title && <h3 className="alert-title">{content.title}</h3> }
        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if(content.redirect)
            {
                window.location.replace(content.redirect);
            }
            else
            {
                props.onClose();
            }
          }}
        >
          <span>Close</span>
        </button>

      </Modal.Footer>
    </Modal>
  );
}
