import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContentAPI from 'api/content';
import logo from 'assets/images/bdo-logo.svg';

export default function Navbar() {

  const [redirectURL, setRedirectURL] = useState('');
  const checkRedirectConfiguration = async () => {

    const result = await ContentAPI.redirects();
    let allow_redirect = false;
    result.forEach((setting)=>{
       if(setting.setting_name == 'frontend_redirect')
       {
          if((setting.setting_value).toLowerCase() == 'on')
          {
            allow_redirect = true;
          }
       }
    });


    if(allow_redirect)
    {
      result.forEach((setting)=>{
         if(setting.setting_name == 'frontend_redirect_url')
         {
             setRedirectURL(setting.setting_value);
         }
      });
    }
          
  }

  useEffect(() => {  
    checkRedirectConfiguration();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-primary mb-lg-5">
      <div className="container-fluid">
       
      { redirectURL != '' &&
        <a className="navbar-brand" href={redirectURL}>
          <img src={logo} className="img-fluid no-print" alt="BDO Logo" />
        </a>
      }
      {
        redirectURL == '' && <img src={logo} className="img-fluid no-print" alt="BDO Logo" />
      }
        
        <h6 className="app-name"></h6>  
       {/*
       <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDropdown"
          aria-controls="navbarDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      */}

        {/* <div className="collapse navbar-collapse justify-content-end" id="navbarDropdown">
          <ul className="nav">
            <li className="nav-item"><a href="#" className="nav-link txt-white-h-none semi-bold">Credit Cards</a></li>
          </ul>
        </div> */}
      </div>
    </nav>
  )
}
