import { Link } from "react-router-dom";
import { LoanOffer, ConfirmRec, ConfirmedReceipt } from "common/types";
import LoanAPI from "api/loan";
import { createMoneyFormatter } from 'common/util';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { ErrorMessages, getErrorMessage } from 'common/error_message';
import { useState, useEffect } from "react";
import ContentAPI from 'api/content';
import AlertModal from 'components/modals/AlertModal';
import { InlineSpinner } from "components/util/ui";
import './summary.css';
interface SummaryProps {
  readonly loanOffer: LoanOffer
  onChange?: (value: LoanOffer) => void
}
interface AlertModalContent {
  show_icon: boolean,
  title: string,
  content:string
}

export default function Summary(props: SummaryProps) {
  const { loanOffer } = props;
  const formatMoney = createMoneyFormatter();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loanOfferUpdate, setloanOfferUpdate] = useState<LoanOffer>(loanOffer);
  const [receiptReferenceNo, setreceiptReferenceNo] = useState<ConfirmedReceipt>();
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [AlertModalContent, setAlertModalContent] = useState<AlertModalContent>({
    show_icon: false,
    title: '',
    content: '',
  });
  const [confirmreceipt, setConfirmReceipt] = useState<ConfirmRec>({
    reference_no: loanOffer?.refno,
    availed_amount: loanOffer.loanAmount,
    monthly_amort: loanOffer.installment,
    payable: loanOffer.paymentTerm.payableMonths,
    monthly_add_on: loanOffer.paymentTerm.interestRate,
    effective_interest_rate: loanOffer.paymentTerm.effectivity,
    payment_holiday: (loanOffer.availPaymentHoliday == true) ? 'Yes' : 'No',
    availment_fee: loanOffer.installment
  });
  const navigate = useNavigate();

  enum ErrorState {
  NONE,
  TID,
  EXPIRED_CARD,
  CONNECTION_ERROR,
  RESERVED,
}

var TRANSLATIONS = {
  [ErrorState.NONE]: {
    header: '',
    message: ''
  },
  [ErrorState.TID]: {
    header: 'Unable to confirm transaction',
    message: 'TID Error'
  },
  [ErrorState.EXPIRED_CARD]: {
    header: 'Unable to confirm transaction',
    message:  "The card used in this transaction has expired",
  },
  [ErrorState.CONNECTION_ERROR]: {
    header: "We're unable to process your Cash Availment Request at this time.",
    message: "Please call the Cash Availment Hotline (02) 8688-121. 8:30AM-4:30PM on banking days to process your transaction.",
  },
   [ErrorState.RESERVED]: {
    header: "We're unable to process your Cash Availment Request at this time.",
    message: "Please call the Cash Availment Hotline (02) 8688-121. 8:30AM-4:30PM on banking days to process your transaction.",
  },
}

  const [errorState, setErrorState] = useState<ErrorState>(ErrorState.NONE);

  const hideCustomModal = () => {
    setShowCustomModal(false);

    if(errorState == ErrorState.CONNECTION_ERROR || 
      errorState == ErrorState.EXPIRED_CARD || 
      errorState == ErrorState.TID || 
      errorState == ErrorState.RESERVED)
    {
      navigate('/start');
    }
  }
  const showModal = () => setShowCustomModal(true);

  const edit = () =>{
    navigate('/offer')
  };

  const submit = async () => {
    setErrorState(ErrorState.NONE);
    setIsSubmitting(true);
    try {

      let session = sessionStorage.getItem('bdoca_sess');
      if(session)
      {
        let session_data = JSON.parse(session);
        const result = await LoanAPI.confirm(confirmreceipt, session_data.session_token);
       
        if (result.status && result.refno) {
          loanOffer.refReceipt.receiptReferenceNo = result.refno;
          setloanOfferUpdate(loanOffer)
          navigate(`/receipt`)
        } else {
          if( (result.remarks).toLowerCase() == 'expired card')
          {

            console.log("Confirmation: Expired Card");
            setErrorState(ErrorState.EXPIRED_CARD);
            var error_content = getErrorMessage('expired_card');
            setAlertModalContent({
              show_icon: true,
              title: error_content.title,
              content: error_content.spiel
            });
            showModal();
          }
          else if( (result.remarks).toLowerCase() == 'reserved' || ((result.remarks).toLowerCase().indexOf("reserved") != -1))
          {

            console.log("Confirmation: Reserved Error");
           setErrorState(ErrorState.RESERVED);
            var error_content = getErrorMessage('reserved');
            setAlertModalContent({
              show_icon: true,
              title: error_content.title,
              content: error_content.spiel
            });
            showModal();
          }
          else if( (result.remarks).toLowerCase() == 'tid error')
          {

            console.log("Confirmation: TID Error");
            setErrorState(ErrorState.TID);
            var error_content = getErrorMessage('tid');
            setAlertModalContent({
              show_icon: true,
              title: error_content.title,
              content: error_content.spiel
            });
            showModal();
          }
          else
          {
            console.log("Confirmation: Connection Error");
            setErrorState(ErrorState.CONNECTION_ERROR);
            var error_content = getErrorMessage('connection_error');
            setAlertModalContent({
              show_icon: true,
              title: error_content.title,
              content: error_content.spiel
            });
            showModal();
          }
        }
      }
      else
      {
          console.log("Confirmation: Session Token not found");
          navigate('/start?error=session_expired');
      }
    } catch (e) {

        console.log("Confirmation: Connection Error");
        setErrorState(ErrorState.CONNECTION_ERROR);
        var error_content = getErrorMessage('connection_error');
        setAlertModalContent({
          show_icon: true,
          title: error_content.title,
          content: error_content.spiel
        });
        showModal();
    }
    setIsSubmitting(false)
  }
  return (

    <div className="container px-lg-5 py-3">
      <Row >
        <Col className=" default-box-shadow w-bg bdo_card summary_page" lg={{ span: 5, offset: 3 }}>
          <Row>
            <Col lg="12">
              <h4>Review and confirm</h4>
              <p className="normalText mt-3">Kindly review the details below before confirming this transaction</p>
            </Col>
            <Col lg="12">
              <div className="summary-bg">
                <p className="normalText">Amount to Avail</p>
                <h2 className="text_value_big"><span className="small-php">PHP</span> {formatMoney(loanOffer.loanAmount)}</h2>

                <p className="normalText mt-4">
                  To be credited to your Account <span className="acc-dot">•••••••• {loanOffer.account}</span>
                </p>
              </div>
            </Col>

            <Col lg="12" className="less-mb">
              <p className="normalText">Monthly Amortization</p>
              <h2 className="text_value_big"><span className="small-php">PHP</span> {formatMoney(loanOffer.installment)}</h2>
            </Col>

            <Col lg="12" className="less-mb summary-list mt-2">
              <Row>
                <Col lg="6">
                  <p className="normalText">Payable in</p>
                </Col>
                <Col lg="6">
                  <p className="alignLeftVal text_value_big">{loanOffer.paymentTerm.payableMonths} months</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="6">
                  <p className="normalText">Monthly Add-on rate</p>
                </Col>
                <Col lg="6">
                  <p className="alignLeftVal text_value_big">{loanOffer.paymentTerm.interestRate}%</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="6">
                  <p className="normalText">Effective Interest Rate</p>
                </Col>
                <Col lg="6">
                  <p className="alignLeftVal text_value_big">{loanOffer.paymentTerm.effectivity}%</p>
                </Col>
              </Row>
              <hr />
              {loanOffer.merchantIdHoliday != '' && 
              <Row>
                <Col lg="6">
                  <p className="normalText">Payment Holiday</p>
                </Col>
                <Col lg="6">
                  <p className="alignLeftVal text_value_big">{(loanOffer.availPaymentHoliday == true) ? 'Yes' : 'No'}</p>
                </Col>
              </Row>
              }
              {
                loanOffer.merchantIdHoliday != '' && 
                <hr/>
              }
              <Row>
                <Col lg="6">
                  <p className="normalText">Installment Availment Fee</p>
                </Col>
                <Col lg="6">
                  <p className="alignLeftVal text_value_big">P200</p>
                </Col>
              </Row>
            </Col>

            <Col lg="12">
              <div className="grey-bg mb-5 mt-4">
                <p className="normalText" style={loanOffer.availPaymentHoliday == false ? {} : { display: 'none' }} >You will be billed <b> P{formatMoney(loanOffer.installment + 200)}</b> on the next statement cycle after the transaction posting date.</p>

                <p className="normalText" style={loanOffer.availPaymentHoliday == true ? {} : { display: 'none' }}>You will be billed <b>P200</b> Installment Availment Fee on the next statement cycle. Your monthly amortization of <b>P{formatMoney(loanOffer.installment)}</b> will start on the second statement cycle and every month thereafter after the transaction posting date.</p>
              </div>
            </Col>

          </Row>
          <Row className="btns-wrapper mobile-co">
            <Col lg="6">
              { /*<Link to="/offer" className="btn btn-outline-primary fullwidth">Edit</Link> */}
                <button className="btn btn-outline-primary fullwidth" disabled={isSubmitting} onClick={edit}>
                  Edit
                </button>
            </Col>
            <Col lg="6">
              <button className="btn btn-primary fullwidth btn-confirm" disabled={isSubmitting} onClick={submit}>
              {isSubmitting
                  ? <> Confirm<InlineSpinner /> </>
                  : 'Confirm'
                }
              </button>


              {/* <Link to="/receipt" className="btn btn-primary fullwidth" onClick={submit}>Confirm</Link> */}

            </Col>
          </Row>
        </Col>


      </Row>
       <AlertModal
            className="alert-modal"
              backdrop="static"
              keyboard={false}
              size="lg"
              fullscreen="sm-down"
              scrollable={true}
              show={showCustomModal}
              onClose={hideCustomModal}
              content={AlertModalContent}
              centered
            />
    </div>
  )
}
