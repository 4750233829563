import { toFormData, __wrappedFetch } from "./util"

const GoogleAPI = {
  API_URL: process.env.REACT_APP_API_URL + '/api',

  ENDPOINTS: {
    // verify_token: '/verify-captcha',
    verify_token: '/server/verify_captcha.php'
  },
  async verify_token(token: string): Promise<any> {
    try
    { 
      // const result = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.verify_token}`, {
      const result = await __wrappedFetch(`${this.ENDPOINTS.verify_token}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: toFormData({
        token: token
      })
    
    })
    .then(response => response.json())
      return Promise.resolve({
        ...result,
        status: result.success == true ? 1 : 0,
      })

    } catch(e:any) { 
      return Promise.resolve({
        message: e.message,
        status: 0,
      });
    }
  },

  
} as const;

export default GoogleAPI;
