import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";

// import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from 'react-google-invisible-recaptcha';

import AlertModal from 'components/modals/AlertModal';
import MaintenanceModal from 'components/modals/MaintenanceModal';
import HotlineModal from 'components/bdo-hotline/HotlineModal';
import LoanAPI from "api/loan";
import OtpAPI from 'api/otp';
import ContentAPI from 'api/content';
import GoogleAPI from 'api/google';
import { ErrorMessages, getErrorMessage } from 'common/error_message';
import { Maintenance } from 'common/maintenance';
import { InlineSpinner } from "components/util/ui";
import { Row, Col } from 'react-bootstrap';

interface AlertModalContent {
  show_icon: boolean,
  title: string,
  content:string
}

interface OtpSettingState {
  otp_expiration: number,
  otp_interval: number,
  otp_maxattempts: number,
  otp_maxresend: number,
  otp_templockduration: number
}

interface MaintenanceModalContent {
  show_icon: boolean,
  title: string,
  content:string,
  image:string,
  redirect: string,
}

enum ErrorState {
  NONE,
  CANCEL_CODE,
  INVALID_CODE,
  CONNECTION_ERROR,
  INVALID_MOBILE,
  MAX_ATTEMPTS_REACHED,
  LOW_CREDIT_LIMIT,
  PROMO_NOT_EXISTS,
  INVALID_DATE
}

var TRANSLATIONS = {
  [ErrorState.NONE]: {
    header: '',
    message: ''
  },
  [ErrorState.CANCEL_CODE]: {
    header: 'Cancel Code Header',
    message: 'Cancel Code Body'
  },
  [ErrorState.INVALID_CODE]: {
    header: 'Invalid Code Header',
    message: 'Invalid Code Body'
  },
  [ErrorState.CONNECTION_ERROR]: {
    header: 'Connection Error Header',
    message: 'Connection Error Body'
  },
  [ErrorState.INVALID_MOBILE]: {
    header: 'Invalid Mobile Header',
    message: 'Invalid Mobile Body'
  },
  [ErrorState.MAX_ATTEMPTS_REACHED]: {
    header: '',
    message:  "You have reached the maximum numbers of attempts. Kindly try again in 24 hours.",
  },
   [ErrorState.LOW_CREDIT_LIMIT]: {
    header: '',
    message: 'Your available Credit Limit is below the minimum allowable Cash Availment amount of P10,000. You may re-apply once you have sufficient Credit Limit.',
  },
  [ErrorState.PROMO_NOT_EXISTS]: {
    header: 'Unable to Proceed',
    message: 'Promo does not exists',
  },
  [ErrorState.INVALID_DATE]: {
    header: 'Unable to Proceed',
    message: 'Promo already ended',
  },
}
export default function ReferenceNumberInput(props: {
  onChange?: (value: string) => void
}) {
  const navigate = useNavigate();
  const SITE_KEY = process.env.REACT_APP_GCAPTCHA_SITE_KEY;
  const captchaRef = useRef<any>(null);
  const refInput = useRef<any>(null);
  const [refno, setRefno] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showHotline, setShowHotline] = useState(false);
  const [displayedError, setDisplayedError] = useState('');
  const [AlertModalContent, setAlertModalContent] = useState<AlertModalContent>({
    show_icon: false,
    title: '',
    content: '',
  });
  const [showCustomModal, setShowCustomModal] = useState(false);

  const [MaintenanceModalContent, setMaintenanceModalContent] = useState<MaintenanceModalContent>({
    show_icon: false,
    title: '',
    content: '',
    redirect: '',
    image: '',
  });
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [AOtpSettings, setAOtpSettings] = useState<OtpSettingState>(
  {
    otp_expiration: 5,
    otp_interval: 3,
    otp_maxattempts: 1,
    otp_maxresend: 1,
    otp_templockduration: 1
  }
);

  // var referrer = document.referrer;
  // console.log("referrer url: ",referrer);
  const loadOtpSettings = async () => {
      // Load Settings
      const result = await OtpAPI.all_otp_settings();
      setAOtpSettings({
        otp_expiration: result.frontend_otp_expiration,
        otp_interval: result.frontend_otp_interval,
        otp_maxattempts: result.frontend_otp_maxattempts,
        otp_maxresend: result.frontend_otp_maxresend,
        otp_templockduration: result.frontend_otp_templockduration
      })
   
  }

  const loadErrorMessages = async () => {
    const result = await ContentAPI.error_messages();
    if(result.data)
    {
      var error_list = result.data;
      error_list.forEach((error:any)=>{
          if(error.code == 'cancel_code_error')
          {
            TRANSLATIONS[ErrorState.CANCEL_CODE].header = error.title;
            TRANSLATIONS[ErrorState.CANCEL_CODE].message = error.spiel;
          }
          else if(error.code == 'invalid_code')
          {
            TRANSLATIONS[ErrorState.INVALID_CODE].header = error.title;
            TRANSLATIONS[ErrorState.INVALID_CODE].message = error.spiel;
          }
          else if(error.code == 'connection_error')
          {
            TRANSLATIONS[ErrorState.CONNECTION_ERROR].header = error.title;
            TRANSLATIONS[ErrorState.CONNECTION_ERROR].message = error.spiel;
          }
          else if(error.code == 'invalid_mobile_number')
          {
            TRANSLATIONS[ErrorState.INVALID_MOBILE].header = error.title;
            TRANSLATIONS[ErrorState.INVALID_MOBILE].message = error.spiel;
          }
          else if((error.code).toLowerCase() == 'insufficient_credit_limit')
          {
            TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].header = error.title;
            TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].message = error.spiel;
          }
          else if((error.code).toLowerCase() == 'promo_not_exist')
          {
            TRANSLATIONS[ErrorState.PROMO_NOT_EXISTS].header = error.title;
            TRANSLATIONS[ErrorState.PROMO_NOT_EXISTS].message = error.spiel;
          }
          else if((error.code).toLowerCase() == 'invalid_date')
          {
            TRANSLATIONS[ErrorState.INVALID_DATE].header = error.title;
            TRANSLATIONS[ErrorState.INVALID_DATE].message = error.spiel;
          }
           else if((error.code).toLowerCase() == 'otp_invalid_max_attempt')
          {
            TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header = error.title;
            TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message = error.spiel;
          }
      });
    }

  };

  useEffect(() => {
    loadOtpSettings();
    loadErrorMessages();
  }, [])

  const hideCustomModal = () => {
    setShowCustomModal(false);
  }

  const hideMaintenanceModal = () => {
    setShowMaintenanceModal(false);
  }
  const captchaResolved= () => {

    let token = captchaRef.current.callbacks.getResponse();
    verifyReferenceNumber(token,refInput.current.value);
  }
  const verifyReferenceNumber = async (token:string, submitted_refno:string) =>{

      if(token)
      {
        let valid_token = await GoogleAPI.verify_token(token);


        if(valid_token.status == 1)
        {
          const regex = /^[a-zA-Z0-9]+$/;
          const valid_ref_pattern = regex.test(submitted_refno);

          if(submitted_refno.length == 10 && valid_ref_pattern)
          {
            const result = await LoanAPI.status(submitted_refno);
            if (result.status && result.mobile && result.no_of_otp < (AOtpSettings.otp_maxattempts)) {
              navigate(`/otp?refno=${submitted_refno}`)
            } else {
              setIsInvalid(true)
              captchaRef.current.callbacks.reset();
              if( (result.remarks).toLowerCase() == 'invalid_refno')
              {
                console.log("Verify Reference Number: Invalid Reference Number");  
                setDisplayedError((TRANSLATIONS[ErrorState.INVALID_CODE].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.INVALID_CODE].header,
                  content: TRANSLATIONS[ErrorState.INVALID_CODE].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'cancelled')
              {  

                console.log("Verify Reference Number: Cancel Code");  
                setDisplayedError((TRANSLATIONS[ErrorState.CANCEL_CODE].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.CANCEL_CODE].header,
                  content: TRANSLATIONS[ErrorState.CANCEL_CODE].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'promo_not_exist')
              {  

                console.log("Verify Reference Number: Promo Not Exists");  
                setDisplayedError((TRANSLATIONS[ErrorState.PROMO_NOT_EXISTS].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.PROMO_NOT_EXISTS].header,
                  content: TRANSLATIONS[ErrorState.PROMO_NOT_EXISTS].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'invalid_date')
              {  

                console.log("Verify Reference Number: Promo Ended/Invalid Date");  
                setDisplayedError((TRANSLATIONS[ErrorState.INVALID_DATE].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.INVALID_DATE].header,
                  content: TRANSLATIONS[ErrorState.INVALID_DATE].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'otp_invalid_max_attempt')
              {
                console.log("Verify Reference Number: Invalid OTP. Max Attempt");   
                setDisplayedError((TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header,
                  content: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'invalid_mobile_number')
              {
                console.log("Verify Reference Number: Invalid Mobile Number 1");   
                setDisplayedError((TRANSLATIONS[ErrorState.INVALID_MOBILE].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.INVALID_MOBILE].header,
                  content: TRANSLATIONS[ErrorState.INVALID_MOBILE].message
                });
              }
              else if( (result.remarks).toLowerCase() == 'valid_refno' && (result.mobile == false))
              {
                console.log("Verify Reference Number: Invalid Mobile Number 2");   
                setDisplayedError((TRANSLATIONS[ErrorState.INVALID_MOBILE].message).toString());
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.INVALID_MOBILE].header,
                  content: TRANSLATIONS[ErrorState.INVALID_MOBILE].message
                });
              }
              else if((result.remarks).toLowerCase() == 'valid_refno' && (result.no_of_otp >= (AOtpSettings.otp_maxattempts)))
              {
                  console.log("Verify Reference Number: Max OTP Attempts Reached");  
                  setDisplayedError((TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message).toString());
                  setAlertModalContent({
                    show_icon: true,
                    title: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].header,
                    content: TRANSLATIONS[ErrorState.MAX_ATTEMPTS_REACHED].message
                  });
              }
              else
              {

                console.log("Verify Reference Number: Connection Error"); 
                // setDisplayedError('Invalid Reference Number');
                setAlertModalContent({
                  show_icon: true,
                  title: TRANSLATIONS[ErrorState.CONNECTION_ERROR].header,
                  content: TRANSLATIONS[ErrorState.CONNECTION_ERROR].message
                });
              }
            
              setShowCustomModal(true)
              setIsSubmitting(false)
            }
          }
          else
          {
            setIsInvalid(true)
            console.log("Verify Reference Number: Invalid Reference Number");  
            setDisplayedError((TRANSLATIONS[ErrorState.INVALID_CODE].message).toString());
            setAlertModalContent({
              show_icon: true,
              title: TRANSLATIONS[ErrorState.INVALID_CODE].header,
              content: TRANSLATIONS[ErrorState.INVALID_CODE].message
            });
            
            setShowCustomModal(true)
            setIsSubmitting(false)
          }
        }
        else
        {

          captchaRef.current.callbacks.reset();
          setIsInvalid(true)
          setDisplayedError('You are a robot');
          setIsSubmitting(false)

        }
      }
      else
      {
        console.log("Verify Reference Number: Please verify that you are not a robot using Captcha");
        setIsInvalid(true)
        setDisplayedError('Please verify that you are not a robot');
        setIsSubmitting(false)
      }
  }

  const submit = async (e:any) => {
    e.preventDefault();
    setDisplayedError('');
    setIsSubmitting(true);
    var maintenance:any = Maintenance.Settings[0];
    if(maintenance.status == "1")
    {  
      try {
      captchaRef.current.callbacks.execute();
    
    } catch (e:any) {
      console.log("An error occured while submitting the form");
      console.log(e.message);
      setIsInvalid(true)
     // setDisplayedError('Invalid Reference Number');
        setAlertModalContent({
          show_icon: true,
          title: "We're unable to process your Cash Availment Request at this time",
          content: 'Please call the Cash Availment Hotline (02) 8688-121. 8:30AM-4:30PM on banking days to process your transaction'
        });
      setIsSubmitting(false)
      setShowCustomModal(true)
       // setShowHotline(true)
    }

    }
    else
    {
      console.log("Site under Maintenance");
      setMaintenanceModalContent({
          show_icon: true,
          title: maintenance.title,
          content: maintenance.description,
          redirect: maintenance.redirect,
          image: maintenance.image_path
      });
      setShowMaintenanceModal(true)
       setIsSubmitting(false);
    }
  
  }

  return (
    <div className="container-sm container-md container-lg container-xl container-xxl, pt-4">
      <Row>
        <Col md={2}>
        </Col>
        <Col md={8}>
          <div className="otp default-box-shadow w-bg">
            <div className="card border-0" >
              <p className="card-title">We need to make sure it's you</p>
              <div className="card-body text-center">
                <Row>
                  <Col md={12}>
                    <p className="card-text text-center">To continue, enter the <strong>Reference Code</strong><br /> sent to your mobile number/email address.</p>

                  </Col>
                  <Col md={{ span: 6, offset: 3 }} className="mt-3">
                    <input
                      className={`form-control otp-ref text-center ${isInvalid ? 'is-invalid' : ''}`}
                      type="text"
                      style={{ textTransform: 'inherit' }}
                      onChange={(e) => {
                        setRefno(e.target.value.toUpperCase());
                          props.onChange?.(e.target.value.toUpperCase())
                      }}

                      onBlur={(e) => {
                        setRefno(e.target.value.toUpperCase());
                        props.onChange?.(e.target.value.toUpperCase())
                      }}
                      value={refno}
                      maxLength={10}
                      placeholder="Reference Code"
                      ref={ refInput }
                    />


                    { refno != "" &&
                    <Recaptcha
                        sitekey={ SITE_KEY ? SITE_KEY : '' }
                        ref={ captchaRef }
                        onResolved={ captchaResolved } 
                      />
                    }
                  </Col>

                  <Col md={12}>
                        {
                          displayedError != '' &&
                            <div className="text-danger mt-3">{ displayedError }</div>
                        }
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <div className="d-grid sm-mt-3 px-2 sm-px-2">
                      <button
                        className="btn btn-primary mt-5"
                        disabled={refno.length < 10 || isSubmitting}
                        onClick={event => submit(event)}
                      >
                        {isSubmitting
                          ? <> Please wait... <InlineSpinner /> </>
                          : 'Submit'
                        }
                      </button>
                    </div>
                  </Col>
                </Row>





              </div>

            </div>
          </div>
        </Col>
        <Col md={2}>
        </Col>
      </Row>

    <HotlineModal
        show={showHotline}
        onHide={() => setShowHotline(false)}
      />
     <AlertModal
        className="alert-modal"
          backdrop="static"
          keyboard={false}
          size="lg"
          fullscreen="sm-down"
          scrollable={true}
          show={showCustomModal}
          onClose={hideCustomModal}
          content={AlertModalContent}
          centered
        />

         <MaintenanceModal
        className="maintenance alert-modal"
          backdrop="static"
          keyboard={false}
          size="lg"
          fullscreen="sm-down"
          scrollable={true}
          show={showMaintenanceModal}
          onClose={hideMaintenanceModal}
          content={MaintenanceModalContent}
          centered
        />
    </div>

  )
}
