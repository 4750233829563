import { useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import closeIcon from 'assets/images/x-lg.svg';
import errorIcon from 'assets/images/error-icon.png';
// import Undertaking, { UndertakingProps } from "./CustomModalContent";

interface AlertModalProps extends ModalProps {
  onClose: VoidFunction
  content: any
};

export default function UndertakingModal(props: AlertModalProps) {
  const { onClose, content,...other } = props;

  return (
    <Modal {...other}>
      <Modal.Header closeButton={false}></Modal.Header>
      <Modal.Body>
        { content.show_icon && <img src={errorIcon} className="error-icon" />}
        { content.title && <h3 className="alert-title">{content.title}</h3> }
        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.onClose();
          }}
        >
          <span>Close</span>
        </button>

      </Modal.Footer>
    </Modal>
  );
}
