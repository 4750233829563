import { PaymentTerm } from "common/types";
import { useState } from "react"
// import Form from 'react/bootstrap';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';
interface PaymentTermSelectorProps {
  terms: PaymentTerm[],
  defaultSelectedIndex: number,
  definedAmount: number,
  onChange?: (paymentTerm: PaymentTerm, index?: number) => void
}



function PaymentTermNode(props: PaymentTerm & {
  isSelected?: boolean,
  onClick: VoidFunction,
  ikey: number
}) {
  return (
    <Col lg="1" style={{ }} className="apt-radio-btn">
      <Form.Check
        inline
        label=""
        name="group1"
        type='radio'
        id={`inline-radio-${props.ikey}`}
        checked={props.isSelected ? true : false}
        onClick={props.onClick}
        onChange={()=>{}}
      />

    </Col>
  )
}

export default function PaymentTermSelector(props: PaymentTermSelectorProps) {
  const [selectedIndex, setSelectedIndex] = useState(props.defaultSelectedIndex  );

  return (
    <Row>

      {props.terms.map((value, index) => {
        let paymentdetails: any = value;
        let monthly = (props.definedAmount * paymentdetails.factorRate).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return (
          <Col lg="12" key={index} onClick={() => {
            setSelectedIndex(index);
            props.onChange?.(value);
          }}>
            <Row>
              {(() => {
                if (index != 0) {
                  return (
                    <hr />
                  )
                }
              })()}
              <PaymentTermNode 
                ikey={index}
                isSelected={selectedIndex === index}
                onClick={() => {
                  setSelectedIndex(index);
                  props.onChange?.(value);
                }}
                {...value}
              />
              <Col className="terms-column">
                <p className="textheaderC terms-wrapper"> <span className="textheaderC-span terms"> {value.payableMonths} </span> </p>
                <div className="apt-show-mob">
                  <p className="apt-month"><b>PHP {monthly}/month for {value.payableMonths} months</b></p>
                  <p className="apt-add-on">Converted Monthly Add-On Rate {paymentdetails.interestRate }%</p>
                  <p className="apt-int-rate">Effective Interest Rate {paymentdetails.effectivity }%</p>
                </div>
              </Col>
              <Col className="monthly-addons-column">
              <p className="subtextA"><span className="subtextA-span">Converted Monthly factor Rate </span>{paymentdetails.interestRate}%</p>
              </Col>
              <Col  className="eir-column">
              <p className="subtextB"><span className="subtextB-span">{paymentdetails.effectivity}%</span></p>
              </Col>
              <Col className="monthly-payment-column">
              <p className="textheaderB"><span className="textheaderB-span ">P{monthly}/mo</span></p>
              </Col>

            </Row>

          </Col>
        )
      }

      )

      }


    </Row>

  )
}
