export function  __wrappedFetch(input: RequestInfo, init?: RequestInit | undefined) {
  return fetch(input, init)
    .then(response => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }
      return response
    })
}

export function toFormData(elements: Record<string, any>): FormData {
  const formData = new FormData()
  Object.entries(elements).forEach((value) => formData.append(value[0], value[1]))
  return formData
}
