import { useEffect } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import Navbar from 'components/navbar/Navbar';
import Footer from 'components/footer/Footer';

import './App.css';


export default function App() {
  const location = useLocation();

  const breadcrumbClass = (path: string) => path === location.pathname
    ? 'breadcrumb-item active text-primary'
    : 'breadcrumb-item';

    let timer: any;
    const TIMEOUT = 1000 * 60 * 15; // 15 minutes
    const idleTimer = () => {
      window.onload = resetTimer;
      // tracking events
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
    };
     const logout = () => {
      clearTimeout(timer);
      alert('Session expired due to inactivity');
      window.location.reload();
    };
    const resetTimer = () => {
      clearTimeout(timer);
      
      if(location.pathname != '/' && location.pathname != '/start')
        timer = setTimeout(logout, TIMEOUT);
    };


  useEffect(() => {
    idleTimer();
  });

  return (
    <>
      <Navbar />

      <div className="container d-none d-md-block px-lg-5 pt-sm-4 pt-lg-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className={breadcrumbClass('/start')}><a className="disabled">Reference code</a></li>
            <li className={breadcrumbClass('/otp')}><a className="disabled">One time password</a></li>
            <li className={breadcrumbClass('/offer')}><a className="disabled">Offer</a></li>
            <li className={breadcrumbClass('/summary')}><a className="disabled">Summary</a></li>
            <li className={breadcrumbClass('/receipt')}><a className="disabled">Receipt</a></li>
          </ol>
        </nav>
      </div>
      <Outlet />
      <Footer />
    </>
  );
}
