import { Modal, ModalProps, Row, Col } from "react-bootstrap";

export default function HotlineModal(props: ModalProps) {
  return (
    <Modal {...props} className="payment-holiday-modal" centered>
      <Modal.Header closeButton={true}></Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h5>
              <b>Payment Holiday Example</b>
            </h5>
            <p>
              Here's an example of how your Payment Holiday and First
              Installment Payment will be reflected on your Statement of
              Accounts:
            </p>
          </Col>
          <Col md={12} className="text-center  table-responsive ">
            <table className="payment-holiday-table table text-center">
              <tr>
                <th>Cut off</th>
                <th>Transaction Date</th>
                <th>October 15, 2024 SOA</th>
                <th>November 15, 2024 SOA</th>
                <th>December 15, 2024 SOA</th>
                <th>First Installment Payment Due**</th>
              </tr>
              <tr>
                <td rowSpan={2} className="cutoffheight">
                  Every 15th of the month
                </td>
                <td>October 1-15, 2024</td>
                <td>
                  <b className="highlight">Billing Holiday*</b>
                </td>
                <td>Cardholder is billed of 1st monthly amortization</td>
                <td>
                  Cardholder is billed of 2nd monthly amortization and every
                  month thereafter until end of installment term
                </td>
                <td>December 10, 2024</td>
              </tr>
              <tr>
                <td>October 16-31, 2024</td>
                <td>N/A</td>
                <td>
                  <b className="highlight">Billing Holiday*</b>
                </td>
                <td>
                  Cardholder is billed of 1st monthly amortization & every month
                  thereafter until end of installment term
                </td>
                <td>June 09, 2024</td>
              </tr>
            </table>
            <div className="mobile-payment-holiday-table">
              <div className="payment-holiday">
                <div className="ph-row">
                  <div>Cut off</div>
                  <div>Every 15th of the month</div>
                </div>
                <div className="ph-row">
                  <div>Transaction Date</div>
                  <div>October 1-15, 2024</div>
                </div>
                <div className="ph-row">
                  <div>October 15, 2024 SOA</div>
                  <div>
                    <b className="highlight">Billing Holiday*</b>
                  </div>
                </div>
                <div className="ph-row">
                  <div>November 15, 2024 SOA</div>
                  <div>Cardholder is billed of 1st monthly amortization</div>
                </div>
                <div className="ph-row">
                  <div>December 15, 2024 SOA</div>
                  <div>
                    Cardholder is billed of 2nd monthly amortization & every
                    month thereafter until end of installment term
                  </div>
                </div>
                <div className="ph-row">
                  <div>First Installment Payment Due**</div>
                  <div>December 10, 2024</div>
                </div>
              </div>

              <div className="payment-holiday">
                <div className="ph-row">
                  <div>Cut off</div>
                  <div>Every 15th of the month</div>
                </div>
                <div className="ph-row">
                  <div>Transaction Date</div>
                  <div>October 16-31, 2024</div>
                </div>
                <div className="ph-row">
                  <div>October 15, 2024 SOA</div>
                  <div>N/A</div>
                </div>
                <div className="ph-row">
                  <div>November 15, 2024 SOA</div>
                  <div>
                    <b className="highlight">Billing Holiday*</b>
                  </div>
                </div>
                <div className="ph-row">
                  <div>December 15, 2024 SOA</div>
                  <div>
                    Cardholder is billed of 1st monthly amortization & every
                    month thereafter until end of installment term
                  </div>
                </div>
                <div className="ph-row">
                  <div>First Installment Payment Due**</div>
                  <div>January 09, 2024</div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-4 gray-text">
            <p>
              *Payment Holiday = First monthly installment will not be billed
              yet
              <br />
              **Payment Due Date = Estimate 25 days after SOA cut-off
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center d-none">
        <a className="txt-blue txt-decor-none fw-bold" href="tel:(02)8688-1212">
          Call (02) 8688-1212
        </a>
      </Modal.Footer>
    </Modal>
  );
}
