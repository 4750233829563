export let Maintenance : any= {
	Settings: [],
};

export function isMaintenance() {
  var is_maintenance = false;
  if(Maintenance.Settings.length)
  {
    return Maintenance.Settings[0];
  }
  return is_maintenance;
}