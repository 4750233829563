export function createMoneyFormatter() {
  const formatter = new Intl.NumberFormat('en-PH',  {
    style: 'currency',
    currency: 'PHP'
  })
  return (value: number) => formatter.format(value).slice(1); // remove Peso sign
}
// Jan 1, 2000 01:10 PM
export function createDateTimeFormatter() {
  const formatter = new Intl.DateTimeFormat('en-PH', {
    dateStyle: 'medium',
    timeStyle: 'short'
    // month: 'short'
  })
  return (value: Date) => formatter.format(value);
}
