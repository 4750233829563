import CheckLogo from "assets/images/check-icon.png";
import { LoanOffer } from "common/types";
import { createDateTimeFormatter, createMoneyFormatter } from "common/util";
import { Link } from "react-router-dom";

import LoanAPI from "api/loan";
import { useNavigate } from "react-router-dom";
import dlIcon from "assets/images/request.svg";
import printIcon from "assets/images/print-icon.png";
export default function Receipt(props: { readonly loanOffer: LoanOffer }) {
  const { loanOffer } = props;
  const formatMoney = createMoneyFormatter();
  const now = createDateTimeFormatter()(new Date());
  const navigate = useNavigate();

  const endTransaction = () => {
    sessionStorage.removeItem("la");
    sessionStorage.removeItem("t");
    sessionStorage.removeItem("bdoca_sess");
    sessionStorage.removeItem("bdoca_lo");
    window.location.href = "https://www.bdo.com.ph/personal";
  };

  // const downloadPdf =  async () => {
  //    let session = sessionStorage.getItem('bdoca_sess');
  //    if(session)
  //    {
  //      let session_data = JSON.parse(session);
  //       const result = await LoanAPI.getDownloadPdfLink(loanOffer.refReceipt.receiptReferenceNo ? loanOffer.refReceipt.receiptReferenceNo : '', session_data.refno, session_data.session_token );

  //       if (result) {
  //          const link = document.createElement('a');
  //          // link.href = result.url;
  //          link.href = result;
  //          link.target = "_blank";
  //          document.body.appendChild(link);
  //          link.click();
  //          document.body.removeChild(link);
  //          // return true;
  //        }
  //      }
  //      else
  //      {
  //         console.log("Download PDF: Session Token not found");
  //         navigate('/start?error=session_expired');
  //      }
  // };

  const downloadPdf = async () => {
    let session = sessionStorage.getItem("bdoca_sess");
    if (session) {
      let session_data = JSON.parse(session);
      const result = await LoanAPI.downloadPdfv2(
        loanOffer.refReceipt.receiptReferenceNo
          ? loanOffer.refReceipt.receiptReferenceNo
          : "",
        session_data.refno,
        session_data.session_token
      );
      console.log(result);
      if (result) {
        const link = document.createElement("a");
        const blob = new Blob([result], { type: "application/pdf" });
        // link.href = result.url;
        link.href = URL.createObjectURL(blob);
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.log("Download PDF: Session Token not found");
      navigate("/start?error=session_expired");
    }
  };
  return (
    <div className="container py-3 section-to-print">
      <div className="trans-receipt default-box-shadow w-bg">
        <div className="row">
          <div className="card-group">
            <div className="card">
              <div className="card-header text-center">
                <p className="txt-yellow fw-bold mb-1">{now}</p>
                <p className="fw-bold">
                  Reference No: {loanOffer.refReceipt.receiptReferenceNo}
                </p>
              </div>
              <div className="card-body text-center">
                <div className="receipt-col-wrapper">
                  <img src={CheckLogo} className="img-fluid img-lg mt-4 mb-4" />

                  <p className="normalText mb-1 mt-3">Availed Amount</p>
                  <h2 className="mb-4">
                    <span className="small-txt">PHP</span>{" "}
                    {formatMoney(loanOffer.loanAmount)}
                  </h2>

                  <p className="normalText mb-3">
                    To be credited to your Account
                    <br />
                    ••••••••{loanOffer.account}
                  </p>
                  <p className="normalText txt-gray mt-3 mb-0">
                    Monthly Amortization
                  </p>
                  <h4 className="fw-bold mb-0">
                    PHP {formatMoney(loanOffer.installment)}
                  </h4>
                </div>
              </div>
              <div className="card-footer">
                <div className="lb-bg no-print">
                  <p className="normalText">
                    Your BDO Installment Card Cash Availment application has
                    been received and will be subject to BDO’s approval. Please
                    expect a status update via SMS or email in{" "}
                    <b>4-5 banking days</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header dl-print">
                <div className="hstack gap-5 no-print">
                  <div className="ms-auto download-link">
                    <span>
                      <img
                        src={dlIcon}
                        onClick={downloadPdf}
                        className="img-fluid rounded-start"
                        alt="Download Icon"
                      />
                    </span>
                    <span onClick={downloadPdf}>Download</span>
                  </div>
                  <div className="print-link" onClick={(e) => window.print()}>
                    <span>
                      <img
                        src={printIcon}
                        className="print-icon img-fluid rounded-start"
                        alt="Download Icon"
                      />
                    </span>
                    <span>Print</span>
                  </div>
                </div>
              </div>
              <div className="card-header dl-print save-share">
                <div className="hstack gap-5">
                  <div className="ms-auto">
                    <span>
                      <img
                        src={dlIcon}
                        onClick={downloadPdf}
                        className="img-fluid rounded-start"
                        alt="Download Icon"
                      />
                    </span>
                    <span onClick={downloadPdf}>Download</span>
                  </div>
                </div>
              </div>
              <div className="card-body tr-right">
                <div className="receipt-col-wrapper">
                  <div className="hstack gap-3 mt-3 mb-3">
                    <div className="">Payable in</div>
                    <div className="ms-auto"></div>
                    <div className="alignLeftVal fw-bold">
                      {loanOffer.paymentTerm.payableMonths} months
                    </div>
                  </div>

                  <hr className="dropdown-divider" />

                  <div className="hstack gap-3 mt-3 mb-3">
                    <div className="">Monthly Add-on rate</div>
                    <div className="ms-auto"></div>
                    <div className="alignLeftVal fw-bold">
                      {loanOffer.paymentTerm.interestRate}%
                    </div>
                  </div>

                  <hr className="dropdown-divider" />

                  {
                    // loanOffer.availPaymentHoliday &&
                    // <>
                    //   <p className="mt-4 fw-bold fs-8">Payment Holiday</p>
                    //   <p>First monthly installment will be billed on the second statement cycle after the transaction posting date.</p>
                    // </>
                  }

                  <div className="hstack gap-3 mt-3 mb-3">
                    <div className="">Effective Interest Rate</div>
                    <div className="ms-auto"></div>
                    <div className="alignLeftVal fw-bold">
                      {loanOffer.paymentTerm.effectivity}%
                    </div>
                  </div>

                  <hr className="dropdown-divider" />

                  {loanOffer.merchantIdHoliday != "" && (
                    <div className="hstack gap-3 mt-3 mb-3">
                      <div className="">Payment Holiday</div>
                      <div className="ms-auto"></div>
                      <div className="alignLeftVal fw-bold">
                        {loanOffer.availPaymentHoliday == true ? "Yes" : "No"}
                      </div>
                    </div>
                  )}

                  {loanOffer.merchantIdHoliday != "" && (
                    <hr className="dropdown-divider" />
                  )}

                  <div className="hstack gap-3 mt-3 mb-3">
                    <div className="">Installment Availment Fee</div>
                    <div className="ms-auto"></div>
                    <div className="alignLeftVal fw-bold">P200</div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="lb-bg print-only">
                  <p className="normalText">
                    Your BDO Installment Card Cash Availment application has
                    been received and will be subject to BDO’s approval. Please
                    expect a status update via SMS or email in{" "}
                    <b>4-5 banking days</b>
                  </p>
                </div>
                <div className="d-grid mt-5">
                  <button
                    onClick={endTransaction}
                    className="no-print btn btn-primary"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
