import ContentAPI from "api/content";
import { useEffect, useState } from "react"

export interface UndertakingProps {
  defaultChecked?: boolean,
  promoMechanicsLink: string,
  content:string,
  onCheckChange: (checked: boolean) => void
}

const KEY_MECHANICS = 'promo_mechanics_1';

export default function Undertaking(props: UndertakingProps) {

  return (
    <>
      {props.content === '' && <div>
        <span className="text-muted">Loading mechanics, Please wait...</span> <br /><br />
        <p className="placeholder-glow">
          <span className="placeholder col-8"></span>
          <span className="placeholder col-10"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-11"></span>
          <br />
          <br />


          <span className="placeholder col-8"></span>
          <span className="placeholder col-10"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-11"></span>
          <span className="placeholder col-11"></span>
        </p>

      </div>}

      <div className="undertaking" dangerouslySetInnerHTML={{ __html: (props.content).replace(/href/g, "target='_blank' href") }}></div>

      {props.content !== '' && <div className="form-check-wrapper">
      <div className="form-check mt-2">
        <label className="form-check-label undertaking" htmlFor="undertakingAgreement">
          I have read, understood and agree to the 
          { props.promoMechanicsLink && <a href={ props.promoMechanicsLink } target="_blank"> Promo Mechanics</a> } 
          { !props.promoMechanicsLink && <a href="#"> Promo Mechanics</a> } and Cash Availment Undertaking.
        </label>
        <input className="form-check-input"
          type="checkbox"
          id="undertakingAgreement"
          defaultChecked={props.defaultChecked}
          onChange={(e) => props.onCheckChange(e.target.checked)} />
      </div>
      </div>}
    </>
  )
}
