import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentAPI from "api/content";

export default function Footer() {
  const [visitorIP, setVisitorIP] = useState("0.0.0.0");

  const getVisitorIP = async () => {
    const result = await ContentAPI.ip_address();
    if (result?.ip_address) {
      setVisitorIP(result?.ip_address);
    }
  };

  useEffect(() => {
    // getVisitorIP();
  }, []);

  return (
    <footer className="">
      <p className="text-center footer-txt">
        BDO Contact Center: (+632) 8888-0000
        <br />
        BDO Unibank, Inc. is regulated by the Bangko Sentral ng Pilipinas:
        https://www.bsp.gov.ph.
        <br />
        The BDO, BDO Unibank and other BDO-related trademarks are owned by BDO
        Unibank, Inc. All Rights Reserved.
        {/*<div className="visitor-ip" style={{color: "#b6b6b691"}}>{ visitorIP }</div>*/}
      </p>
    </footer>
  );
}
