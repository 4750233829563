import { PaymentTerm } from "common/types"
import { toFormData, __wrappedFetch } from "./util"

interface GenericResultType {
  status: number,
  remarks: string,
  
}
interface GenericResultTypeA {
  status: number,
  remarks: string,
}

export type MapUrlIdResultTypeA = GenericResultTypeA & {
  refno?: string
}
export type MapUrlIdResultType = GenericResultType & {
  refno?: string
}

export type OfferStatusResultType = GenericResultType & {
  refno?: string,
  mobile?: boolean,
  max_loan: number,
  min_loan: number,
  no_of_otp: number
}

export type OfferDetailsResultType = GenericResultType & {
  status?: string,
  remarks?: string,
  uuid?: string,
  refno?: string,
  // cardholder_first_name?: string,
  card_first_name?: string,
  promo_id?: string,
  mp_limit?: number,
  minimum_loan?: number,
  as_of?: string,
  mobile_mask?: string,
  card_mask?: string,
  account?: string,
  allow_payment_holiday?: boolean,
  payment_terms?: PaymentTerm[],
  promo_mechanics_url?: string,
  merchant_id_holiday?: string,
  promo_end_date?: string
}

const LoanAPI = {
  API_URL: process.env.REACT_APP_API_URL + '/api',

  ENDPOINTS: {
    // refno: '/fetch_refno',
    refno: '/server/fetch_refno.php',
    // status: '/offers/:refno/status',
    status: '/server/offer_status.php',
    // details: '/offer/details',
    details: '/server/offer_details.php',
    // confirm: '/send-receipt',
    confirm: '/server/confirm_offer.php',
    // downloadPdf: '/download-pdf'
    downloadPdf: '/server/download_pdf.php'
  },

  async mapUrlId(urlId: string): Promise<MapUrlIdResultType> {
    // const result = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.refno}`, {
    const result = await __wrappedFetch(`${this.ENDPOINTS.refno}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: toFormData({ urlid: urlId })
    })
      .then(response => response.json());

    return Promise.resolve({
      status: result.status === 'success' ? 1 : 0,
      remarks: result.remarks,
      refno: result?.data?.reference_number
    })
  },

  async status(refno: string): Promise<OfferStatusResultType> {
    // const resource = this.API_URL + this.ENDPOINTS.status.replace(':refno', encodeURIComponent(refno))
    // const result = await __wrappedFetch(resource, {
    const result = await __wrappedFetch(`${this.ENDPOINTS.status}`, {
      headers: {
        'Accept': 'application/json'
      },
      method: 'POST',
      body: toFormData({ refno: encodeURIComponent(refno) }) // Added to pass data to local api
    })
      .then(response => response.json())

    return Promise.resolve({
      status: result.status === 'success' ? 1 : 0,
      remarks: result.remarks,
      mobile: result.mobile,
      min_loan: (result.minimum_loan ? result.minimum_loan : 0),
      max_loan: (result.mp_limit ? result.mp_limit : 0),
      no_of_otp: (result.no_of_otp)
    })
  },


  async details(bearer: string, session_token: string): Promise<OfferDetailsResultType> {

      let loan_offer = sessionStorage.getItem('bdoca_lo');
      if(loan_offer)
      {
          let loan_offer_data = JSON.parse(loan_offer);
          return Promise.resolve({
              ...loan_offer_data
          });
      }
      else
      {
        // const result = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.details}?session_token=` + session_token , {
           const result = await __wrappedFetch(`${this.ENDPOINTS.details}`, {
       
          method: 'POST',
          headers: { 
            'Authorization': 'Bearer ' + bearer,
            'Accept': 'application/json' },
          body: toFormData({ st: session_token, bt: bearer }) // Added to pass data to local api
        })
          .then(response => response.json())
        return Promise.resolve({
          ...result,
          status: result.status === 'success' ? 1 : 0,
        })

      }
  },

  async confirm(confirmData: any, session_token: string): Promise<MapUrlIdResultTypeA> {
    
    // const result = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.confirm}`, {
    const result = await __wrappedFetch(`${this.ENDPOINTS.confirm}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: toFormData({...confirmData, session_token})
    })
      .then(response => response.json());

    return Promise.resolve({
      status: (result.status).toLowerCase() == 'success' ? 1 : 0,
      remarks: result.remarks,
      refno: result.refno,
    })
  },



  async downloadPdf(receipt_no : string, session_token: string): Promise<any> {
    const result = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.downloadPdf}` + '?receipt_no=' + receipt_no, {
      method: 'GET',
       headers: {
          'Content-Type': 'application/pdf',
        },
    })
    return Promise.resolve(result)
  },

  async downloadPdfv2(receipt_no : string, refno: string, session_token: string): Promise<any> {
    const result = await __wrappedFetch(`${this.ENDPOINTS.downloadPdf}`, {
      method: 'POST',
        body: toFormData({receipt_no: receipt_no, refno: refno, session_token: session_token}),
        headers: {
            Accept: 'application/pdf'
        },
    })
    .then(response => response.blob());
    return Promise.resolve(result)
  },

  async getDownloadPdfLink(receipt_no : string, refno: string, session_token: string): Promise<any> {
    let download_resource = "https://www2.cashinstallment.uat.bdo.com.ph/api";
    return Promise.resolve(`${download_resource}${this.ENDPOINTS.downloadPdf}` + '?receipt_no=' + receipt_no + '&refno=' + refno + '&session_token=' + session_token);
  }
} as const;

export default LoanAPI;
