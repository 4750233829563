import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContentAPI from 'api/content';
import { AuthContext, BEARER_TOKEN_KEY } from 'auth/Auth';

import CurrencyInput from 'react-currency-input-field';
import UndertakingModal from 'components/undertaking/UndertakingModal';
import HotlineModal from 'components/bdo-hotline/HotlineModal';
import AlertModal from 'components/modals/AlertModal';
import PaymentHolidayModal from 'components/bdo-paymentholiday/PaymentHolidayModal';
import PaymentTermSelector from 'components/payment-term-selector/PaymentTermSelector';


import LoanAPI, { OfferDetailsResultType } from 'api/loan';
import { LoanOffer, PaymentTerm, ConfirmedReceipt } from 'common/types';
import { createMoneyFormatter } from 'common/util';
import { Placeholder } from 'components/util/ui'

import './offer.css'
import InfoIcon from 'assets/images/info-icon.png';
import OTPInfoIcon from 'assets/images/Icon-Information.svg';
import { Row, Col } from 'react-bootstrap';
interface OfferProps {
  onChange?: (value: LoanOffer) => void
}

interface AlertModalContent {
  show_icon: boolean,
  title: string,
  content:string
}

const EMPTY_PAYMENT_TERM: PaymentTerm = {
  factorRate: 0,
  interestRate: 0,
  payableMonths: 0,
  effectivity: 0
}
const EMPTY_RECEIPT: ConfirmedReceipt = {
  receiptReferenceNo: 'initial'
}

enum ErrorState {
  NONE,
  MINIMUM_AMOUNT,
  EXCEED_AMOUNT,
  INVALID_AMOUNT,
  CONNECTION_ERROR,
  LOW_CREDIT_LIMIT,
}


var TRANSLATIONS = {
  [ErrorState.NONE]: {
    header: '',
    message: ''
  },
  [ErrorState.MINIMUM_AMOUNT]: {
    header: '',
    message: 'Minimum Cash Availment amount is P10,000.00'
  },
  [ErrorState.EXCEED_AMOUNT]: {
    header: '',
    message:  "You exceed the maximum amount.Try again",
  },
  [ErrorState.INVALID_AMOUNT]: {
    header: '',
    message: 'Loan amount should be divisible by P1,000.00',
  },
   [ErrorState.CONNECTION_ERROR]: {
    header: 'Connection Error Header',
    message: 'Connection Error Body'
  },
   [ErrorState.LOW_CREDIT_LIMIT]: {
    header: '',
    message: 'Your available Credit Limit is below the minimum allowable Cash Availment amount of P10,000. You may re-apply once you have sufficient Credit Limit.',
  },
}

export default function Offer(props: OfferProps) {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const formatMoney = createMoneyFormatter();

  const [_pageLoaded, _setPageLoaded] = useState(false);

  // Contains the value from loan details API
  const [details, setDetails] = useState<OfferDetailsResultType>(undefined!);
  // Selected payment term
  const [paymentTerm, setPaymentTerm] = useState<PaymentTerm>(undefined!);
  const [minInterest, setMinInterest] = useState(1.0)

  // Dynamic properties
  const [loanAmount, setLoanAmount] = useState('10000.00');
  const [MaxMp, setMaxMp] = useState(0);
  const [validLoanAmount, setvalidLoanAmount] = useState(10000);
  const [availPaymentHoliday, setAvailPaymentHoliday] = useState(false);

  // Modal visibility flags
  const [showUndertaking, setShowUndertaking] = useState(false);
  const [showHotline, setShowHotline] = useState(false);
  const [showPaymentHoliday, setShowPaymentHoliday] = useState(false);

  const [paymentTermIndex, setPaymentTermIndex] = useState(0);
  const [forceRedirect, setForceRedirect] = useState(false);
  const [AlertModalContent, setAlertModalContent] = useState<AlertModalContent>({
    show_icon: false,
    title: '',
    content: '',
  });
  const [showCustomModal, setShowCustomModal] = useState(false);

  useEffect(() => {
    loadOffer();
    loadErrorMessages();
  }, [])

  useEffect(() => {
    onChange();
  }, [loanAmount, paymentTerm, availPaymentHoliday])

   const loadErrorMessages = async () => {
    const result = await ContentAPI.error_messages();
    if(result.data)
    {
      var error_list = result.data;
      error_list.forEach((error:any)=>{
          if(error.code == 'loan_amount')
          {
            TRANSLATIONS[ErrorState.INVALID_AMOUNT].header = error.title;
            TRANSLATIONS[ErrorState.INVALID_AMOUNT].message = error.spiel;
          }
          else if(error.code == 'minimum_amount')
          {
            TRANSLATIONS[ErrorState.MINIMUM_AMOUNT].header = error.title;
            TRANSLATIONS[ErrorState.MINIMUM_AMOUNT].message = error.spiel;
          }
          else if(error.code == 'exceed_amount')
          {
            TRANSLATIONS[ErrorState.EXCEED_AMOUNT].header = error.title;
            TRANSLATIONS[ErrorState.EXCEED_AMOUNT].message = error.spiel;
          }
          else if(error.code == 'connection_error')
          {
            TRANSLATIONS[ErrorState.CONNECTION_ERROR].header = error.title;
            TRANSLATIONS[ErrorState.CONNECTION_ERROR].message = error.spiel;
          }
          else if((error.code).toLowerCase() == 'insufficient_credit_limit')
          {
            TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].header = error.title;
            TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].message = error.spiel;
          }
      });
    }

  };
  const loadOffer = async () => {
    try {
      let session = sessionStorage.getItem('bdoca_sess');
      if(session)
      {
        let session_data = JSON.parse(session);
        const result = await LoanAPI.details(authContext.bearerToken || sessionStorage.getItem(BEARER_TOKEN_KEY) || '', session_data.session_token)
       
        if(result.status)
        {
          if( (result.mp_limit ? result.mp_limit : 0) >= 10000)
          {
            sessionStorage.setItem('bdoca_lo', JSON.stringify(result));
            
            setDetails(result);
            // assert(result.payment_terms.length)
            setPaymentTerm(result.payment_terms?.[0] ?? EMPTY_PAYMENT_TERM); // This should not actually happen
            setMinInterest(Math.min(...result.payment_terms?.map(x => x.interestRate) || [0])) // Get lowest interest rate
            setLoanAmount( (  result.minimum_loan ? result.minimum_loan?.toFixed(2).toString() : loanAmount ) )
            setMaxMp( (result.mp_limit ? result.mp_limit : MaxMp) )

            if(sessionStorage.getItem('la'))
            {
               var loan_availed_details = JSON.parse( (sessionStorage.getItem('la') || '{}') );
                setLoanAmount(loan_availed_details.loanAmount);
                setPaymentTerm(loan_availed_details.paymentTerm);
                if(result.payment_terms)
                {
                  for (var i = 0; i < result.payment_terms.length; i++)
                  {
                    if(result.payment_terms[i].payableMonths == loan_availed_details.paymentTerm.payableMonths)
                    {
                      setPaymentTermIndex(i);
                    }  
                  }
                } 

                setAvailPaymentHoliday(loan_availed_details.availPaymentHoliday);
            }
            _setPageLoaded(true);
          }
          else
          {
            console.log("Offers: Insufficient Credit Limit [FE]");
            setForceRedirect(true);
            setAlertModalContent({
              show_icon: true,
              title: TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].header,
              content: TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].message
            });
            setShowCustomModal(true) 
          }
        }
        else
        {
          if(result.remarks.toLowerCase() == 'invalid token')
          {
            console.log("Offers: Invalid Session Token");
              navigate('/start?error=session_expired')
          }
          else if(result.remarks.toLowerCase() == 'insufficient_credit_limit')
          {
            console.log("Offers: Insufficient Credit Limit");
            setForceRedirect(true);
            setAlertModalContent({
              show_icon: true,
              title: TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].header,
              content: TRANSLATIONS[ErrorState.LOW_CREDIT_LIMIT].message
            });
            setShowCustomModal(true) 
          }
          else
          {
         
            console.log("Offers: Connection Error");
            setForceRedirect(true);
            setAlertModalContent({
              show_icon: true,
              title: TRANSLATIONS[ErrorState.CONNECTION_ERROR].header,
              content: TRANSLATIONS[ErrorState.CONNECTION_ERROR].message
            });
            setShowCustomModal(true) 
          }
        }
      }
      else
      {
          console.log("Offers: Session Token not found");
          navigate('/start?error=session_expired')
      }
    } catch (e) {
      // if unauthorized, start login routine
      console.log(e);
      navigate('/start?error=session_expired')
    }
  }

  const computeInstallment = () => +(Number.parseFloat(loanAmount) * paymentTerm?.factorRate).toFixed(2)

  const onChange = () => {

    var loanval: number = parseFloat(loanAmount);
    var mplimitval: number = MaxMp;

    // if (loanval > mplimitval) {
    //   // setLoanAmount(validLoanAmount.toFixed(2).toString())
    // }  
    // if((loanval! % 1000) || (loanval == 0)) {
    //   // setLoanAmount(validLoanAmount.toFixed(2).toString())
    // }
    if (/*valid &&*/ props.onChange) {
      props.onChange({
        loanAmount: Number.parseFloat(loanAmount),
        paymentTerm: paymentTerm,
        installment: computeInstallment(), // TODO
        availPaymentHoliday: availPaymentHoliday,
        cardMask: (details?.card_mask ? details?.card_mask : '' ),
        refno: (details?.refno ? details?.refno : '' ) ,
        refReceipt: EMPTY_RECEIPT,
        account: (details?.account ? details?.account : '' ),
        merchantIdHoliday: (details?.merchant_id_holiday ? details?.merchant_id_holiday : ''),
      });

    }
  }

  const getPromoEndDate = (end_date: string) => {

      var d = new Date(end_date);

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

       return monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
  };

  const fixLoanAmount = () =>{

     if(loanAmount == '')
     {
      setLoanAmount((validLoanAmount).toString());
     }
    // else if( parseFloat(loanAmount) < (validLoanAmount))
    // {
    //    setLoanAmount((validLoanAmount).toString());
    // }
    // else if (parseFloat(loanAmount) > (MaxMp))
    // {
    //   setLoanAmount((MaxMp).toString());
    // }
    else
    {
      setLoanAmount( ((Math.floor(parseFloat(loanAmount)/1000)) * 1000 ).toString() ) ;
    }
  }

  const paymentHolidayCheckbx = (result: boolean) => {
    setAvailPaymentHoliday(result)
  }
  const hideUndertaking = () => {
    setShowUndertaking(false);
  }
  const hideCustomModal = () =>{
    setShowHotline(false);
    if(forceRedirect)
    {
      navigate('/start?error=session_expired');
    }
  }
  const showModal = () => setShowUndertaking(true);
  const onAgreeClick = () => {
      
    var loan_availed_details = {
      loanAmount: Number.parseFloat(loanAmount),
      paymentTerm: paymentTerm,
      installment: computeInstallment(), // TODO
      availPaymentHoliday: availPaymentHoliday,
    };

    sessionStorage.setItem('la', JSON.stringify(loan_availed_details));


    navigate('/summary')
  }
  const validator = ({ }) => {
    // let return_vals = {};
    type returns = {
      [key: string]: any;
    };
    const return_vals: returns = {
    };
    const loanval = parseFloat(loanAmount);
    return_vals.is_valid = true;
    return_vals.remarks = '';
    if (loanval > (details.mp_limit ? details.mp_limit : -1)) {
      return_vals.is_valid = false;
      return_vals.remarks = TRANSLATIONS[ErrorState.EXCEED_AMOUNT].message;
    }
    if (loanval < 9999) {

      return_vals.is_valid = false;
      return_vals.remarks = TRANSLATIONS[ErrorState.MINIMUM_AMOUNT].message;
    }
    if (loanval % 1000 > 0)
    {
      return_vals.is_valid = false;

      return_vals.remarks = TRANSLATIONS[ErrorState.INVALID_AMOUNT].message;

    }
    return return_vals;
  }

  return (
    !_pageLoaded ? <div>
      <Placeholder />
         <AlertModal
        className="alert-modal"
          backdrop="static"
          keyboard={false}
          size="lg"
          fullscreen="sm-down"
          scrollable={true}
          show={showCustomModal}
          onClose={hideCustomModal}
          content={AlertModalContent}
          centered
        />
        </div> :
      <div className="container px-lg-5 py-3 offer">
        <Row>
          <Col lg="6" xl={{ span: 6, offset: 0 }}>
            <Row>
              <Col lg="12" xl={{ span: 10, offset: 1 }} className="offer default-box-shadow w-bg card_paddings">
                <p className="normalText titleText">Dear <b>{details?.card_first_name}</b>,</p>

                <p className="normalText">
                  You can avail cash up to <span className="fw-bold">PHP {formatMoney(details?.mp_limit || 0)} </span>
                  with your BDO Installment Card ending in
                  {''} <span className="fw-bold">{details?.card_mask} </span>
                  and enjoy monthly add-on rates for as low as <span className="fw-bold">{minInterest}%!</span>
                </p>
              </Col>
              <Col lg="12">&nbsp;</Col>
              <Col lg="12" xl={{ span: 10, offset: 1 }} className="offer default-box-shadow w-bg card_paddings">
                <p className="darkHeaderText">How much would you like to avail?</p>
                <p className='normalText'>Please input the desired amount below or use the slider to adjust the amount.</p>

                <div className="mt-2 offer-hm-to-avail">
                  <span className="currencyTextA fw-bold me-2 currencyBorder">
                    PHP &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <CurrencyInput
                    style={{ position: 'relative', bottom: '5px' }}
                    className="form-control d-inline-block special_input moneyval"
                    step={1000}
                    value={loanAmount}
                    onBlur={(value) => fixLoanAmount()}
                    onValueChange={(value) => setLoanAmount(value || '')}
                  />
                  <div>

                    {(loanAmount) && validator(true) &&
                      <span className="text-danger">{validator(true).remarks}</span>
                    }
                    &nbsp;
                  </div>

                  {/* 
                  {(+loanAmount % 1000) !== 0 &&
                    <div className="text-danger">Please enter a multiple of 1,000</div>}
                  {(parseFloat(loanAmount) > details.mp_limit) &&
                    <div className="text-danger">You exceed the maximum amount.</div>} */}
                </div>
                <range-slider min={ (details.minimum_loan ? details.minimum_loan.toString() : '0') } max={(details.mp_limit ? details.mp_limit.toString() : '0')} step="1000" value={loanAmount}
                  onInput={(e) => { setLoanAmount((e.target as HTMLInputElement).value) }}
                ></range-slider>

                <div className="hstack offer-stack gap-3 my-3">
                  <div className="fw-bold">PHP {formatMoney(details.minimum_loan ? details.minimum_loan : 0)}</div>
                  <div className="bg-light ms-auto"></div>
                  <div className="fw-bold">PHP {formatMoney(details.mp_limit ? details.mp_limit : 0)}</div>
                </div>






              </Col>
              <Col lg="12">&nbsp;</Col>
              <Col lg="12" xl={{ span: 10, offset: 1 }} className="offer offer-apt default-box-shadow w-bg card_paddings">
                <p className="textheaderA">Select available payment term</p>
                <div className="desktop-apt">
                  <ul className="list-group-head list-group list-group-horizontal flex-fill">
                    <li className="list-group-item">Term (months)</li>
                    <li className="list-group-item">Monthly Add-on Rate</li>
                    <li className="list-group-item">Effective Interest Rate</li>
                    <li className="list-group-item">Monthly Amortization</li>
                  </ul>
                </div>
                <PaymentTermSelector terms={details.payment_terms || []} onChange={setPaymentTerm} defaultSelectedIndex={paymentTermIndex}

                  definedAmount={ 
                    loanAmount == "" ? 0 : 
                    ( 
                      Number.parseFloat(loanAmount) >= (details?.minimum_loan ? details?.minimum_loan : 10000) && Number.parseFloat(loanAmount) <= (details?.mp_limit ? details?.mp_limit : 0) ? 
                      Number.parseFloat(loanAmount) : 0 
                    )} />


              </Col>
            </Row>
          </Col>
          <Col lg="1" className="midFiller col-hide"></Col>

          <Col lg="5" >
            <Row>
              <Col lg="12" xl={{ span: 10 }} className="offer offer-right default-box-shadow w-bg card_paddings">
                <Row>
                  <Col lg="11" className="lb-bg ">
                    <div style={{ margin: '15px' }}>
                      <p className="">Your monthly installment is</p>
                      {
                        (parseFloat(loanAmount) >= (details?.minimum_loan ? details?.minimum_loan : 10000)) && (parseFloat(loanAmount) <= (details?.mp_limit ? details?.mp_limit : 0)) ?
                        (<h2 className=""><span className='small-php'>PHP</span> {formatMoney(computeInstallment())}</h2>) :
                        loanAmount == "" ?
                        (<h2 className=""><span className='small-php'>PHP</span> 0</h2>) : 
                        (<h2 className="">-</h2>)
                      }
                      <p className="mt-0">with {paymentTerm.interestRate}% monthly add-on rate</p>
                    </div>
                  </Col>
                  <Col lg="12" className="px-3 pt-3">
                    <p className="normalText">
                      Cash proceeds will be posted in your <br />
                      <span className="fw-bold fs-8 txt-black d-block mt-1">Account •••••••• {details.account} </span>
                    </p>
                  </Col>
                  <Col lg="12" className="px-3">
                    <button className="btn btn-link txt-blue txt-decor-none fw-bold p-0 shadow-none"
                      onClick={() => setShowHotline(true)}
                    >
                      Account inactive or outdated?
                    </button>
                  </Col>
                  <Col lg="12" className="px-3 border-hr">
                    <hr></hr>
                  </Col>
                  <Col lg="12" className='px-3'>
                   {
                     details.merchant_id_holiday && 
                    <div>
                      <p className="textheaderA">
                        Payment Holiday
                      </p>
                      <p className="normalText">First monthly installment will be billed on the second statement cycle after the transaction posting date.</p>
                      <a href='#' className="alert-link" onClick={() => setShowPaymentHoliday(true)}>View an example</a>
                    </div>
                  }
                  </Col>
                  <Col lg="12" className='px-3 pt-3'>
                  {
                   details.merchant_id_holiday && 
                   <div>
                    <small>Are you interested in Payment Holiday?</small>
                    <div className='radio-inline-wrapper'>
                      <div className="form-check form-check-inline form-check-reverse" onClick={(e) => { paymentHolidayCheckbx(true) }}>
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked={(availPaymentHoliday == true) ? true : false} onChange={(e) => { }} />
                        <label className="form-check-label">Yes</label>
                      </div>
                      <div className="form-check form-check-inline form-check-reverse" onClick={(e) => { paymentHolidayCheckbx(false) }}>
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" checked={(availPaymentHoliday == false) ? true : false} onChange={(e) => { }} />
                        <label className="form-check-label">No</label>
                      </div>
                    </div>
                  
                    </div>
                  }

                   <p className="normalText">
                      This exclusive offer is <strong>valid until { getPromoEndDate( (details.promo_end_date ? details.promo_end_date : '')) }</strong>. Terms and conditions apply.
                    </p>
                  </Col>
                  <Col lg="12" className="px-3 border-hr">
                  {
                    details.merchant_id_holiday && 
                    <hr></hr>
                  }
                  </Col>
                  {/* <Col lg="12">
                    {details.allow_payment_holiday &&
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          // defaultChecked={true}
                          checked={availPaymentHoliday}
                          onChange={(e) => setAvailPaymentHoliday(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          I'm Interested with Avail now, Pay later
                        </label>
                        <span className="recent-link">
                          <img src={InfoIcon} className="img-fluid img-xs" />
                          <span className="hovercard">
                            <div className="tooltiptext">
                              First monthly installment will be billed on the second statement cycle after the transaction posting date.
                            </div>
                          </span>
                        </span>
                      </div>}
                  </Col> */}

                  <Col lg="12">
                    <div className="infoIconContainer">
                      <span><img src={OTPInfoIcon} className="img-fluid infoIcon" /></span>
                      <p className="normalText iaFee"  >
                        A <b>P200 Installment Availment fee</b> will be billed for every approved Cash Availment transaction and will be posted in your Statement of Account.
                      </p>

                    </div>
                  </Col>
                  <Col lg="12" className='mt-4'>

                    <div style={{ position: 'relative' }}>
                      <button type="button" className="btn btn-primary availbtn"
                        onClick={ ((parseFloat(loanAmount) > (details.mp_limit ? details.mp_limit : -1) ) || (parseFloat(loanAmount) < validLoanAmount )) ? undefined : showModal}
                      >
                        Avail Cash
                      </button>
                    </div>

                    <UndertakingModal
                      backdrop="static"
                      keyboard={false}
                      size="lg"
                      scrollable={true}
                      show={showUndertaking}
                      onAgreeClick={onAgreeClick}
                      onBackClick={hideUndertaking}
                      promoMechanicsLink={details.promo_mechanics_url}
                    />

                   
                    <PaymentHolidayModal
                      size="xl"
                      show={showPaymentHoliday}
                      onHide={() => setShowPaymentHoliday(false)}
                    />

                    <HotlineModal
                      show={showHotline}
                      onHide={() => setShowHotline(false)}
                    />


                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

        </Row >

      </div >


  )
}
