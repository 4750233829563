export let ErrorMessages = {
	ErrorList: [],
};

export function getErrorMessage(error_code:string) {
  var error_message = {
  	code: '',
  	title: "We're unable to process your Cash Availment Request at this time.",
  	spiel: "Please call the Cash Availment Hotline (02) 8688-121. 8:30AM-4:30PM on banking days to process your transaction."
  };
  ErrorMessages.ErrorList.forEach((error:any)=>{
          if(error.code == error_code)
          {
            error_message = error;
          }
      });
  return error_message;
}