import { __wrappedFetch, toFormData } from "./util"


interface AllOtpSettingsType {
  otp: string,
  frontend_otp_expiration: number,
  frontend_otp_interval: number,
  frontend_otp_maxattempts: number,
  frontend_otp_maxresend: number,
  frontend_otp_templockduration: number,
}
interface OtpSettingsType {
  setting_value: number,
}
interface OtpAuthResultType {
  status: number,
  bearerToken: string,
  code?: string,
  attemptsLeft?: number,
  remarks: string

  // blockedUntil
}
interface GenerateOtpResultType {
  status: number,
  recipient: string, // Mask only
  expires_at: number,
  remarks: string,
  session_token: string,
}

const OtpAPI = {
  API_URL: process.env.REACT_APP_API_URL + '/api',

  ENDPOINTS: {
    generate_otp: '/server/generate_otp.php',
    // generate_otp: '/generate_otp',
    validate_otp: '/server/validate_otp.php',
    // validate_otp: '/validate_otp',
    otp_settings: '/get-otp-settings',
    all_otp_settings: '/server/otp_settings.php',
    // all_otp_settings: '/get-all-otp-settings',
  },

  all_otp_settings() : Promise<AllOtpSettingsType> {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.all_otp_settings}`, {
      const response = await __wrappedFetch(`${this.ENDPOINTS.all_otp_settings}`, {
        method: 'GET'
      })
        .then(response => response.json())

      // normalize
      resolve(response)
    })
  },

  generate(refno: string) : Promise<GenerateOtpResultType> {
    return new Promise(async (resolve, reject) => {
      try
      {
        // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.generate_otp}`, {
        const response = await __wrappedFetch(`${this.ENDPOINTS.generate_otp}`, {
          method: 'POST',
          body: toFormData({ refno })
        })
          .then(response => response.json())

        // normalize
        resolve({
          status: response?.status === 'success' ? 1 : 0,
          recipient: response.recipient,
          expires_at: response?.expiration_date_raw,
          remarks: response?.remarks,
          session_token: response?.token,
        })
      }
      catch(err)
      {
        resolve({
          status: 0,
          remarks: 'Error connecting to API',
          recipient: '',
          expires_at: 0,
          session_token: '',
        })
      }
    })
  },

  authenticate(refno: string, otp: string, session_token: string): Promise<OtpAuthResultType> {
    return new Promise(async (resolve, reject) => {
      // const response = await __wrappedFetch(`${this.API_URL}${this.ENDPOINTS.validate_otp}`, {
      const response = await __wrappedFetch(`${this.ENDPOINTS.validate_otp}`, {
        method: 'POST',
        body: toFormData({refno, otp, session_token })
      })
        .then(response => response.json())

      resolve({
        status: response?.status === 'success' ? 1 : 0,
        bearerToken: response?.bearer_token, // JWT
        code: response?.code,
        attemptsLeft: response?.attempts_left,
        remarks: response?.remarks
      })
    })
  }
} as const;

export default OtpAPI;
