import { Modal, ModalProps } from "react-bootstrap";

export default function HotlineModal(props: ModalProps) {
  return (
    <Modal {...props} className="hotline-modal" centered>
      <Modal.Header closeButton={true}></Modal.Header>
      {/*
      Updated at [0.0.1]
      <Modal.Body className="text-center">
        <p className="fw-bold">Please call our Cash Availment Hotline or visit the <br />
        nearest BDO Branch to process your transaction.</p>

        <div>
          <span>Hotline is open Monday - Friday except holidays</span><br />
          <span>8:30 AM to 4:30 PM</span>
        </div>
      </Modal.Body> */}
      <Modal.Body className="text-center">
        <p className="fw-bold">Please call the Cash Availment Hotline (02) 8688-1212. 8:30AM-4:30PM on banking days to process your transaction.</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center d-md-none">
        <a className="txt-blue txt-decor-none fw-bold" href="tel:(02)8688-1212">
          Call (02) 8688-1212
        </a>
      </Modal.Footer>
    </Modal>
  )
}
