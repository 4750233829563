import React, { DOMAttributes, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import { AuthProvider, BEARER_TOKEN_KEY, DEFAULT_OTP_INTERVAL, RequireAuth } from 'auth/Auth';
import { LoanOffer } from 'common/types';

import App from './App';
import OTP from 'components/otp/OTP';
import ContentAPI from 'api/content';
import { ErrorMessages } from 'common/error_message';
import { Maintenance } from 'common/maintenance';
import Offer from 'components/offer/Offer';
import Summary from 'components/summary/Summary';
import Receipt from 'components/receipt/Receipt';
import Error404 from 'components/error-404/Error404';
import ResolveRefNo from 'components/resolve-refno/ResolveRefno'
import ReferenceNumberInput from 'components/reference-number/ReferenceNumberInput';

// Web component(s)
import RangeSliderElement from 'components/range-slider-element/range-slider-element'
import 'components/range-slider-element/range-slider-element';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import 'assets/css/responsive.css';

import appInfo from '../package.json';
// import reportWebVitals from './reportWebVitals';

/**
 * Declare Web Components
 */
type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['range-slider']: CustomElement<RangeSliderElement>
    }
  }
}


console.log(`You are using version %c${appInfo.version}`, [
  'font-weight: bold',
  'color: #fff',
  'background-color: #0d6efd',
  'padding: 2px 6px',
  'border-radius: 5px'
].join(';'));
const referrer = document.referrer;
var homurla: any = process.env.REACT_APP_HOME_URL;
if(homurla){
  var homeurl: string = decodeURIComponent(homurla);
  if(homeurl){
    if (referrer.length == 0) {
      // window.location.replace(homeurl);
      window.location.href = homeurl;
    }
  }
}
/**
 * Globals*
 */

function ApplicationState() {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [offer, setOffer] = useState<LoanOffer>(undefined!);

  const loadErrorMessages = async () => {
    const result = await ContentAPI.error_messages();
    if(result.data)
    {
      ErrorMessages.ErrorList = result.data;
    }

  };

  const loadMaintenanceSettings = async () => {
    const result = await ContentAPI.maintenance();
    if(result)
    {
      Maintenance.Settings = result;
    }

  };

  useEffect(() => {
    loadErrorMessages();
    loadMaintenanceSettings();

    sessionStorage.removeItem('la');
    sessionStorage.removeItem('bdoca_sess');
    sessionStorage.removeItem('bdoca_lo');
    sessionStorage.removeItem('bdoca_ctc');
    sessionStorage.removeItem('bdoca_stc');
    sessionStorage.removeItem(BEARER_TOKEN_KEY) // remove any bearer token
  }, [])

  const ROUTES = {
    // Used for email link [e.g site.com/link?urlid=123ABC]
    // Automatically resolves reference number based on urlid query param
    "link": <ResolveRefNo onComplete={setReferenceNumber} />,

    //  Initial route
    "start": <ReferenceNumberInput onChange={setReferenceNumber} />,

    "otp": (referenceNumber
      ? <OTP
        resendInterval={DEFAULT_OTP_INTERVAL}
        referenceNumber={referenceNumber}
      />
      : <Navigate to="/start" />
    ),
    "offer": (
      <RequireAuth>
        <Offer onChange={setOffer} />
      </RequireAuth>
    ),
    "summary": (
      <RequireAuth>
        <Summary loanOffer={offer} />
      </RequireAuth>
    ),
    "receipt": (
      <RequireAuth>
        <Receipt loanOffer={offer} />
      </RequireAuth>
    ),
    "*": <Error404 />
  }

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="/start" />}></Route>
        {Object.entries(ROUTES)
          .map(value => <Route key={value[0]} path={value[0]} element={value[1]} />)
        }
      </Route>
    </Routes>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <ApplicationState />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
